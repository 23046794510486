import React, { Component } from "react";
import { connect } from "react-redux";
import { Container, Row, Col } from "reactstrap";

import * as actions from "../../../../store/actions/index";
import "./WritingsIndex.css";
import "./Writing.css";
import "../../../css/media/writings/writings-home.css";
import { Link } from "react-router-dom";

// import { BASE_URL } from "../../../../consts/const";

// import book_img from "../../../../images/writings/book_image.jpeg";
import Tools from "../../../../utils/tools";
// import { Link } from "react-router-dom";

class WritingsByCatotry extends Component {
  state = {
    // writings: [],
  };

  componentDidMount() {
    // go to top start
    var scrollComponent = this;
    document.addEventListener("scroll", function (e) {
      scrollComponent.toggleVisibility();
    });
    // go to top end

    // const { get_writings } = this.props;
    // get_writings();
    const { writings } = this.props;
    this.set_current_writing(writings);
  }

  componentWillUnmount() {
    document.removeEventListener("scroll", () => {}, false);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.writings !== this.props.writings) {
      // console.log(
      //   "-----====",
      //   nextProps.match.params.w_id,
      //   this.props.match.params.w_id
      // );

      // const cat_id = this.props.match.params.id;
      // const w_id = this.props.match.params.w_id;
      // console.log("nextprops", nextProps.writings);
      // const writings_1 = nextProps.writings.filter((item) => {
      //   return item.delete !== "1" && item.category_id === cat_id;
      // });

      // const current_index = writings_1.findIndex((item) => item.id === w_id);
      // this.setState({
      //   writings: writings_1,
      //   current_index,
      // });
      this.set_current_writing(nextProps.writings);
    }
    return true;
  }

  set_current_writing = (writings) => {
    const cat_id = this.props.match.params.id;
    const w_id = this.props.match.params.w_id;
    console.log("nextprops", writings);
    const writings_1 = writings.filter((item) => {
      return item.delete !== "1" && item.category_id === cat_id;
    });

    const current_index = writings_1.findIndex((item) => item.id === w_id);
    this.setState({
      writings: writings_1,
      current_index,
    });
  };

  create_comparator = (key) => {
    return (a, b) => {
      let val_a = a[key];
      let val_b = b[key];

      if (val_a < val_b) {
        return 1;
      }
      if (val_a > val_b) {
        return -1;
      }
      return 0;
    };
  };

  sortByID = () => {
    return (a, b) => {
      if (a.id === "1" && b.id !== "1") {
        return -1;
      }
      return 0;
    };
  };

  moveNext = (forward) => {
    console.log(this.props);
    const { history } = this.props;
    const { current_index, writings } = this.state;
    const cat_id = this.props.match.params.id;
    let next_index;
    if (forward) {
      next_index = (current_index + 1) % writings.length;
      this.setState({
        current_index: next_index,
      });
    } else {
      next_index =
        current_index === 0
          ? writings.length - 1
          : (current_index - 1) % writings.length;
      this.setState({
        current_index: next_index,
      });
    }
    history.replace(`/writings/${cat_id}/${writings[next_index].id}`);
  };

  toggleVisibility() {
    if (window.pageYOffset > 300) {
      this.setState({
        is_visible: true,
      });
    } else {
      this.setState({
        is_visible: false,
      });
    }
  }
  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  render() {
    const { is_visible } = this.state;
    const cat_id = this.props.match.params.id;
    const w_id = this.props.match.params.w_id;
    console.log("w_id", w_id);
    const { writings } = this.props;
    console.log("state", this.state);

    const writing = writings.find((w) => {
      return w.delete !== "1" && w.id === w_id;
    });
    console.log("writing123", writing);
    if (!writing) {
      return null;
    }
    if (!this.state.writings) {
      return null;
    }

    let desktop_html = (
      <>
        <Container className="home-container writing-section reading">
          <br />
          <Row>
            <Col xl="12" className="writing-title writing">
              {writing.title}
            </Col>
          </Row>
          <Row className="control-icon-row">
            <Col xl="12" className="control-icon-col">
              <span
                className="material-icons"
                onClick={() => {
                  this.moveNext(false);
                }}
              >
                arrow_back_ios
              </span>

              <span
                className="material-icons"
                onClick={() => {
                  this.moveNext(true);
                }}
              >
                arrow_forward_ios
              </span>

              <Link to={`/writings/${cat_id}`}>
                <span className="material-icons">reply</span>
              </Link>
            </Col>
          </Row>
          <div class="hr-writing-tag">
            <hr />
          </div>
          <Row>
            <Col xl="12" className="writing-body">
              <div
                dangerouslySetInnerHTML={{
                  __html: Tools.parseMd(writing.body),
                }}
              />
            </Col>
          </Row>
          <Row className="control-icon-row">
            <Col xl="12" className="control-icon-col">
              <span
                className="material-icons"
                onClick={() => {
                  this.moveNext(false);
                }}
              >
                arrow_back_ios
              </span>

              <span
                className="material-icons"
                onClick={() => {
                  this.moveNext(true);
                }}
              >
                arrow_forward_ios
              </span>

              <Link to={`/writings/${cat_id}`}>
                <span className="material-icons">reply</span>
              </Link>
            </Col>
          </Row>
        </Container>
      </>
    );

    let mobile_html = (
      <>
        <Container className="home-container writing-section reading mobile">
          <br />
          <Row>
            <Col xl="12" className="writing-title">
              {writing.title}
            </Col>
          </Row>
          <Row className="control-icon-row">
            <Col xl="12" className="control-icon-col">
              <span
                className="material-icons"
                onClick={() => {
                  this.moveNext(false);
                }}
              >
                arrow_back_ios
              </span>

              <span
                className="material-icons"
                onClick={() => {
                  this.moveNext(true);
                }}
              >
                arrow_forward_ios
              </span>

              <Link to={`/writings/${cat_id}`}>
                <span className="material-icons">reply</span>
              </Link>
            </Col>
          </Row>
          <div>
            <hr />
          </div>
          <Row>
            <Col xl="12" className="writing-body">
              <div
                dangerouslySetInnerHTML={{
                  __html: Tools.parseMd(writing.body),
                }}
              />
            </Col>
          </Row>
          <Row className="control-icon-row">
            <Col xl="12" className="control-icon-col">
              <span
                className="material-icons"
                onClick={() => {
                  this.moveNext(false);
                }}
              >
                arrow_back_ios
              </span>

              <span
                className="material-icons"
                onClick={() => {
                  this.moveNext(true);
                }}
              >
                arrow_forward_ios
              </span>

              <Link to={`/writings/${cat_id}`}>
                <span className="material-icons">reply</span>
              </Link>
            </Col>
          </Row>
        </Container>

        <br />
        <div className="scroll-to-top">
          {is_visible && (
            <div onClick={() => this.scrollToTop()}>
              <span className="material-icons">expand_circle_down</span>
            </div>
          )}
        </div>
      </>
    );

    if (!Tools.is_less_than(1000)) {
      return <>{desktop_html}</>;
    } else {
      return <>{mobile_html}</>;
    }

    // return (
    //   <>
    //     <Container className="home-container writing-section reading">
    //       <br />
    //       <Row>
    //         <Col xl="12" className="writing-title">
    //           {writing.title}
    //         </Col>
    //       </Row>
    //       <Row className="control-icon-row">
    //         <Col xl="12" className="control-icon-col">
    //           <span
    //             className="material-icons"
    //             onClick={() => {
    //               this.moveNext(false);
    //             }}
    //           >
    //             arrow_back_ios
    //           </span>

    //           <span
    //             className="material-icons"
    //             onClick={() => {
    //               this.moveNext(true);
    //             }}
    //           >
    //             arrow_forward_ios
    //           </span>

    //           <Link to={`/writings/${cat_id}`}>
    //             <span className="material-icons">reply</span>
    //           </Link>
    //         </Col>
    //       </Row>
    //       <div>
    //         <hr />
    //       </div>
    //       <Row>
    //         <Col xl="12" className="writing-body">
    //           <div
    //             dangerouslySetInnerHTML={{
    //               __html: Tools.parseMd(writing.body),
    //             }}
    //           />
    //         </Col>
    //       </Row>
    //     </Container>
    //   </>
    // );
  }
}

const mapStateToProps = (state) => {
  return {
    writings: state.writing.writings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    get_writings: () => dispatch(actions.get_writings()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(WritingsByCatotry);
