import { IS_LOCAL } from "../consts/const";

class Tools {
  static GetWindowWidth = () => {
    return window.innerWidth;
  };
  static is_less_than = (width) => {
    return window.innerWidth < width;
  };
  static sortObjArray = (ary, sortColumn, reverse = false) => {
    if (reverse) {
      return ary.sort(
        (a, b) => parseFloat(b[sortColumn]) - parseFloat(a[sortColumn])
      );
    } else {
      return ary.sort(
        (a, b) => parseFloat(a[sortColumn]) - parseFloat(b[sortColumn])
      );
    }
  };

  static getUniqueArrayBy = (arr, key) => {
    return [...new Map(arr.map((item) => [item[key], item])).values()];
  };

  static parseMdForSummary = (md) => {
    //h
    md = md.replace(/[#]{6}(.+)/g, "$1");
    md = md.replace(/[#]{5}(.+)/g, "$1");
    md = md.replace(/[#]{4}(.+)/g, "$1");
    md = md.replace(/[#]{3}(.+)/g, "$1");
    md = md.replace(/[#]{2}(.+)/g, "$1");
    md = md.replace(/[#]{1}(.+)/g, "$1");

    //images
    // md = md.replace(/\!\[([^\]]+)\]\(([^\)]+)\)/g, '<img src="$2" alt="$1" />');
    md = md.replace(/!\[([^\]]+)\]\(([^)]+)\)/g, "");

    return md;
  };

  static parseMd = (md) => {
    //ul
    md = md.replace(/^\s*\n\*/gm, "<ul>\n*");
    md = md.replace(/^(\*.+)\s*\n([^*])/gm, "$1\n</ul>\n\n$2");
    md = md.replace(/^\*(.+)/gm, "<li>$1</li>");

    //ol
    md = md.replace(/^\s*\n\d\./gm, "<ol>\n1.");
    md = md.replace(/^(\d\..+)\s*\n([^\d.])/gm, "$1\n</ol>\n\n$2");
    md = md.replace(/^\d\.(.+)/gm, "<li>$1</li>");

    //blockquote
    md = md.replace(/^>(.+)/gm, "<blockquote>$1</blockquote>");

    //h
    md = md.replace(/[#]{6}(.+)/g, "<h6>$1</h6>");
    md = md.replace(/[#]{5}(.+)/g, "<h5>$1</h5>");
    md = md.replace(/[#]{4}(.+)/g, "<h4>$1</h4>");
    md = md.replace(/[#]{3}(.+)/g, "<h3>$1</h3>");
    md = md.replace(/[#]{2}(.+)/g, "<h2>$1</h2>");
    md = md.replace(/[#]{1}(.+)/g, "<h1>$1</h1>");

    //alt h
    md = md.replace(/^(.+)\n=+/gm, "<h1>$1</h1>");
    md = md.replace(/^(.+)\n-+/gm, "<h2>$1</h2>");


    // test
    // videos
    md = md.replace(
      /[!]{2}\[([^\]]+)\]\(([^)]+)\)/g,
      '<video width="$1"  controls> <source src="$2" type="video/mp4"></video>'
    );
    // test

    //images
    // md = md.replace(/\!\[([^\]]+)\]\(([^\)]+)\)/g, '<img src="$2" alt="$1" />');
    // md = md.replace(
    //   /!\[([^\]]+)\]\(([^)]+)\)/g,
    //   '<img src="$2" width="$1" height="auto" />'
    // );
    md = md.replace(
      /[!]{1}\[([^\]]+)\]\(([^)]+)\)/g,
      '<img src="$2" width="$1" height="auto" class="markdownimage" style="margin-top: 30px; margin-bottom: 30px;"/>'
    );

    //links
    md = md.replace(
      /[[]{1}([^\]]+)[\]]{1}[(]{1}([^)"]+)("(.+)")?[)]{1}/g,
      '<a href="$2" title="$4">$1</a>'
    );

    //font styles
    md = md.replace(/[*_]{2}([^*_]+)[*_]{2}/g, "<b>$1</b>");
    md = md.replace(/[*_]{1}([^*_]+)[*_]{1}/g, "<i>$1</i>");
    md = md.replace(/[~]{2}([^~]+)[~]{2}/g, "<del>$1</del>");
    // md = md.replace(/[\*\_]{2}([^\*\_]+)[\*\_]{2}/g, '<b>$1</b>');
    // md = md.replace(/[\*\_]{1}([^\*\_]+)[\*\_]{1}/g, '<i>$1</i>');
    // md = md.replace(/[\~]{2}([^\~]+)[\~]{2}/g, '<del>$1</del>');

    //pre
    md = md.replace(/^\s*\n```(([^\s]+))?/gm, '<pre class="$2">');
    md = md.replace(/^```\s*\n/gm, "</pre>\n\n");

    //code
    md = md.replace(/[`]{1}([^`]+)[`]{1}/g, "<code>$1</code>");

    //p
    md = md.replace(/^\s*(\n)?(.+)/gm, function (m) {
      return /<(\/)?(h\d|ul|ol|li|blockquote|pre|img)/.test(m)
        ? m
        : "<p>" + m + "</p>";
    });

    //strip p from pre
    md = md.replace(/(<pre.+>)\s*\n<p>(.+)<\/p>/gm, "$1$2");

    return md;
  };

  static get_domain = () => {
    return window.location.host;
  };
  static is_root_domain = () => {
    if (IS_LOCAL) {
      return this.get_domain().includes("3000");
    } else {
      return (
        !this.get_domain().includes("writings") &&
        !this.get_domain().includes("photoart")
      );
    }
  };
  static is_photoart_domain = () => {
    if (IS_LOCAL) {
      return Tools.get_domain().includes("3001");
    } else {
      return Tools.get_domain().includes("photoart");
    }
  };
  static is_writings_domain = () => {
    if (IS_LOCAL) {
      return Tools.get_domain().includes("3002");
    } else {
      return Tools.get_domain().includes("writings");
    }
  };
}

export default Tools;
